import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaHeart, FaCalendarAlt, FaUser } from "react-icons/fa";
import UpcomingBookings from "./UpcomingBookings";
import Favorites from "./Favorites";

const ProfilePage = () => {
  const [activeTab, setActiveTab] = useState("bookings");
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();

  const parseJwt = (token) => {
    if (!token) return null;
    try {
      const base64Url = token.split(".")[1];
      const base64 = decodeURIComponent(
        atob(base64Url)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      return JSON.parse(base64);
    } catch (error) {
      console.error("Error parsing JWT", error);
      return null;
    }
  };

  useEffect(() => {
    // Check if user is logged in
    const token = sessionStorage.getItem("authToken");
    if (!token) {
      navigate("/authpage");
      return;
    }

    // Get user email from token
    const decodedToken = parseJwt(token);
    if (decodedToken && decodedToken.email) {
      setUserEmail(decodedToken.email);
    } else {
      navigate("/authpage");
    }
  }, [navigate]);

  return (
    <div className="container py-5">
      <div className="row mb-5">
        <div className="col-12 text-center">
          <div className="d-flex align-items-center justify-content-center mb-4">
            <FaUser size={40} style={{ color: "#ff3030", marginRight: "15px" }} />
            <h1 className="mb-0">My Profile</h1>
          </div>
          <p className="lead">Manage your bookings and favorite grounds</p>
        </div>
      </div>

      {/* Tabs Navigation */}
      <div className="row mb-4">
        <div className="col-12">
          <ul className="nav nav-tabs nav-fill profile-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === "bookings" ? "active" : ""}`}
                onClick={() => setActiveTab("bookings")}
                style={{
                  backgroundColor: activeTab === "bookings" ? "#242424" : "transparent",
                  color: activeTab === "bookings" ? "#ff3030" : "#fff",
                  borderColor: "#333",
                  borderBottom: activeTab === "bookings" ? "2px solid #ff3030" : "none"
                }}
              >
                <FaCalendarAlt className="me-2" />
                Upcoming Bookings
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === "favorites" ? "active" : ""}`}
                onClick={() => setActiveTab("favorites")}
                style={{
                  backgroundColor: activeTab === "favorites" ? "#242424" : "transparent",
                  color: activeTab === "favorites" ? "#ff3030" : "#fff",
                  borderColor: "#333",
                  borderBottom: activeTab === "favorites" ? "2px solid #ff3030" : "none"
                }}
              >
                <FaHeart className="me-2" />
                Favorite Grounds
              </button>
            </li>
          </ul>
        </div>
      </div>

      {/* Tab Content */}
      <div className="tab-content">
        {activeTab === "bookings" && (
          <div className="tab-pane fade show active">
            <UpcomingBookings profileView={true} />
          </div>
        )}
        
        {activeTab === "favorites" && (
          <div className="tab-pane fade show active">
            <Favorites profileView={true} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;