import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaInstagram, FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";
import logo_icon from "../Assets/logo.png";

export default function Footer() {
  const location = useLocation();
  const hiddenPaths = ["/authpage"];
  
  if (hiddenPaths.includes(location.pathname)) {
    return null;
  }

  return (
    <footer className="pt-5">
      <div className="container">
        <div className="row gy-4">
          {/* Logo and About */}
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-3">
              <img src={logo_icon} alt="Logo" width="60" height="60" />
              <h3 className="ms-3 mb-0 fw-bold">Sport Spot</h3>
            </div>
            <p className="mb-4">
              Book your favorite sports venues easily with SportSpot. Find the perfect 
              ground for cricket, football, badminton, and more games.
            </p>
            <div className="d-flex gap-3">
              <a href="#" className="social-link" aria-label="Facebook">
                <FaFacebookF style={{ color: "#ff3030" }} />
              </a>
              <a href="#" className="social-link" aria-label="Twitter">
                <FaTwitter style={{ color: "#ff3030" }} />
              </a>
              <a href="#" className="social-link" aria-label="Instagram">
                <FaInstagram style={{ color: "#ff3030" }} />
              </a>
            </div>
          </div>
          
          {/* Quick Links */}
          <div className="col-md-3 col-6">
            <h5 className="mb-4 fw-bold">Quick Links</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <Link to="/" className="footer-link">Home</Link>
              </li>
              <li className="mb-2">
                <Link to="/help" className="footer-link">Help Center</Link>
              </li>
              <li className="mb-2">
                <Link to="/favorites" className="footer-link">My Favorites</Link>
              </li>
              <li className="mb-2">
                <Link to="/upcoming-bookings" className="footer-link">My Bookings</Link>
              </li>
            </ul>
          </div>
          
          {/* Popular Sports */}
          <div className="col-md-2 col-6">
            <h5 className="mb-4 fw-bold">Sports</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <a href="/" className="footer-link">Cricket</a>
              </li>
              <li className="mb-2">
                <a href="/" className="footer-link">Football</a>
              </li>
              <li className="mb-2">
                <a href="/" className="footer-link">Badminton</a>
              </li>
              <li className="mb-2">
                <a href="/" className="footer-link">Volleyball</a>
              </li>
            </ul>
          </div>
          
          {/* Contact */}
          <div className="col-md-3" id="contact">
            <h5 className="mb-4 fw-bold">Contact Us</h5>
            <ul className="list-unstyled">
              <li className="mb-3 d-flex align-items-center">
                <FaMapMarkerAlt className="me-2" style={{ color: "#ff3030", minWidth: "18px" }} />
                <span>San Francisco, California</span>
              </li>
              <li className="mb-3 d-flex align-items-center">
                <FaPhone className="me-2" style={{ color: "#ff3030", minWidth: "18px" }} />
                <span>+1 415 236 7807</span>
              </li>
              <li className="mb-3 d-flex align-items-center">
                <FaEnvelope className="me-2" style={{ color: "#ff3030", minWidth: "18px" }} />
                <a href="mailto:sportspotapp.online@gmail.com" className="footer-link">
                  sportspotapp.online@gmail.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      {/* Copyright */}
      <div className="py-3 mt-5" style={{ backgroundColor: "rgba(255, 48, 48, 0.1)" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p className="mb-0">&copy; {new Date().getFullYear()} SportSpot. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}