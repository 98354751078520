import apiConfig from './apiConfig';
import React, { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";
import user_icon from "../Assets/person.png";
import email_icon from "../Assets/email.png";
import password_icon from "../Assets/password.png";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import auth_bg from "../Assets/Owner_Auth_Bg.png";

export default function AuthPage(props) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [pin, setUserPIN] = useState("");
  const [timer, setTimer] = useState(300); // 5 minutes timer in seconds
  const [isExpired, setIsExpired] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const timerInterval = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [emailModal, setShowEmailModal] = useState(false);
  const [emailInput, setEmailInput] = useState("");

  const startTimer = () => {
    let countdown = 300; // 5 minutes
    clearInterval(timerInterval.current); // Clear any existing timer

    timerInterval.current = setInterval(() => {
      countdown--;
      setTimer(countdown);

      if (countdown === 0) {
        clearInterval(timerInterval.current);
        setIsExpired(true); // Timer expired
      }
    }, 1000);
  };

  const validate = useCallback(() => {
    const errors = {};
    if (props.authMode === "SignUp") {
      if (!name.trim()) errors.name = "Name is required";
    }
    if (!email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
      errors.email = "Valid email is required";
    if (!password.trim() || password.length < 6)
      errors.password = "Password must be at least 6 characters long";

    return errors;
  }, [name, email, password, props.authMode]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const formErrors = validate();
      setErrors(formErrors);
      
      if (Object.keys(formErrors).length > 0) {
        return;
      }

      if (props.authMode === "SignUp") {
        try {
          console.log("Sending OTP to:", `${apiConfig.SPORTSPOT_AUTH_URL}/auth/send_otp/${email}`);
          
          await axios.post(
            `${apiConfig.SPORTSPOT_AUTH_URL}/auth/send_otp/${email}`,
            {},
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );

          setShowModal(true);
          setIsExpired(false);
          setTimer(300);
          startTimer();
        } catch (error) {
          console.error("OTP Send Error:", error);
          setMessage(error.response?.data?.message || "Server error");
        }
      } else {
        try {
          console.log("Login Endpoint:", `${apiConfig.SPORTSPOT_MAIN_URL}/auth/login`);
          
          const response = await axios.post(
            `${apiConfig.SPORTSPOT_MAIN_URL}/auth/login`,
            {
              email,
              password,
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );

          const result = response.data;
          
          console.log("Login Response:", result);

          sessionStorage.setItem("authToken", result.access_token);
          sessionStorage.setItem("email", email);
          setMessage("Login successful!");
          navigate("/");
        } catch (error) {
          console.error("Login Error:", error.response || error);
          
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            setMessage(
              error.response.data.detail || 
              "Login failed. Check credentials."
            );
          } else if (error.request) {
            // The request was made but no response was received
            setMessage("No response from server. Please try again.");
          } else {
            // Something happened in setting up the request that triggered an Error
            setMessage("Error: " + error.message);
          }
        }
      }
    },
    [email, password, name, props.authMode, navigate, validate]
  );

  const handlePINVerification = async () => {
    try {
      const response = await axios.post(
        `${apiConfig.SPORTSPOT_AUTH_URL}/auth/verify_otp`,
        {
          pin,
          name,
          email,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setMessage("Account created successfully!");
      setName("");
      setEmail("");
      setPassword("");
      setShowModal(false);
      props.toggleAuthMode("LogIn");
    } catch (error) {
      console.error("PIN Verification Error:", error.response || error);
      setMessage(
        error.response?.data?.message || "Invalid PIN or server error"
      );
    }
  };

  const handleForgetPassword = async () => {
    setShowEmailModal(true);
  };
  
  const submitForgotPassword = async () => {
    if (!emailInput) {
      setMessage("Please enter an email.");
      return;
    }
    
    try {
      const response = await fetch(
        `${apiConfig.SPORTSPOT_AUTH_URL}/auth/forgot_password/${emailInput}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setMessage("Password has been sent to your email!");
      } else {
        setMessage("Failed to send email. Please try again.");
      }
    } catch (error) {
      setMessage(
        "An error occurred. Please check your connection and try again."
      );
    }
    setShowEmailModal(false);
    setEmailInput(""); 
  };

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(""); 
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <div 
      style={{
        backgroundImage: `url(${auth_bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "90vh",
        position: "relative",
      }}
    >
      <div 
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
      ></div>
      
      <div className="container position-relative d-flex justify-content-center">
        <div className="auth-container bg-dark p-4 rounded-3 my-5" style={{maxWidth: "450px", boxShadow: "0 0 20px rgba(255, 48, 48, 0.3)"}}>
          {message && (
            <div className="alert" style={{backgroundColor: "#2a2a2a", color: "#ff3030", border: "1px solid #ff3030"}}>
              {message}
            </div>
          )}
          
          <div className="text-center mb-4">
            <h1 className="fs-2 fw-bold">{props.authMode}</h1>
            <div className="mx-auto mt-2" style={{width: "60px", height: "4px", backgroundColor: "#ff3030"}}></div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="inputs">
              {props.authMode === "SignUp" && (
                <div className="mb-4">
                  <div className="input-group auth-input">
                    <span className="input-group-text border-0 bg-transparent">
                      <img src={user_icon} alt="User Icon" width="24" height="24" />
                    </span>
                    <input
                      type="text"
                      className="form-control border-0 shadow-none bg-transparent"
                      placeholder="Username"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  {errors.name && (
                    <div className="error-text small mt-1">{errors.name}</div>
                  )}
                </div>
              )}
              
              <div className="mb-4">
                <div className="input-group auth-input">
                  <span className="input-group-text border-0 bg-transparent">
                    <img src={email_icon} alt="Email Icon" width="24" height="24" />
                  </span>
                  <input
                    type="email"
                    className="form-control border-0 shadow-none bg-transparent"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {errors.email && (
                  <div className="error-text small mt-1">{errors.email}</div>
                )}
              </div>
              
              <div className="mb-4">
                <div className="input-group auth-input">
                  <span className="input-group-text border-0 bg-transparent">
                    <img src={password_icon} alt="Password Icon" width="24" height="24" />
                  </span>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control border-0 shadow-none bg-transparent"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn bg-transparent border-0 px-3"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? 
                      <EyeOff size={20} style={{color: "#ff3030"}} /> : 
                      <Eye size={20} style={{color: "#ff3030"}} />}
                  </button>
                </div>
                {errors.password && (
                  <div className="error-text small mt-1">{errors.password}</div>
                )}
              </div>
            </div>

            <div className="d-flex flex-column gap-2 mb-4">
              {props.authMode === "LogIn" ? (
                <>
                  <div className="text-end">
                    <span
                      className="text-decoration-none small cursor-pointer"
                      onClick={handleForgetPassword}
                      style={{cursor: "pointer"}}
                    >
                      Forgot Password?
                    </span>
                  </div>
                  <div className="text-center mt-2">
                    <span className="me-2 text-muted">Don't have an account?</span>
                    <span
                      className="cursor-pointer fw-bold"
                      onClick={() => props.toggleAuthMode("SignUp")}
                      style={{cursor: "pointer"}}
                    >
                      Sign Up
                    </span>
                  </div>
                </>
              ) : (
                <div className="text-center">
                  <span className="me-2 text-muted">Already have an account?</span>
                  <span
                    className="cursor-pointer fw-bold"
                    onClick={() => props.toggleAuthMode("LogIn")}
                    style={{cursor: "pointer"}}
                  >
                    Log In
                  </span>
                </div>
              )}
            </div>

            <div className="text-center">
              <button 
                type="submit" 
                className="btn py-2 px-5 fw-bold"
                style={{backgroundColor: "#ff3030", borderRadius: "50px"}}
              >
                {props.authMode === "LogIn" ? "LOG IN" : "SIGN UP"}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* PIN Verification Modal */}
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          clearInterval(timerInterval.current);
          setTimer(300);
          setIsExpired(false);
          setUserPIN("");
        }}
        centered
        contentClassName="bg-dark text-white"
      >
        <Modal.Header closeButton className="border-bottom border-secondary">
          <Modal.Title style={{color: "#ff3030"}}>Enter Verification PIN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>A 4-digit PIN has been sent to your email.</p>
          <input
            type="text"
            className="form-control bg-dark text-white border-secondary mb-3"
            maxLength="4"
            value={pin}
            onChange={(e) => setUserPIN(e.target.value)}
            placeholder="Enter 4-digit PIN"
          />
          {isExpired ? (
            <div className="alert alert-danger">PIN has expired, please try again.</div>
          ) : (
            <div className="text-center">
              <p>
                Time remaining: {Math.floor(timer / 60)}:{timer % 60 < 10 ? "0" : ""}
                {timer % 60}
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="border-top border-secondary">
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
              clearInterval(timerInterval.current);
              setUserPIN("");
            }}
          >
            Close
          </Button>
          <Button 
            style={{backgroundColor: "#ff3030", borderColor: "#ff3030"}}
            onClick={handlePINVerification}
          >
            Verify PIN
          </Button>
        </Modal.Footer>
      </Modal>
      
      {/* Forgot Password Modal */}
      <Modal
        show={emailModal}
        onHide={() => setShowEmailModal(false)}
        centered
        contentClassName="bg-dark text-white"
      >
        <Modal.Header closeButton className="border-bottom border-secondary">
          <Modal.Title style={{color: "#ff3030"}}>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enter your email address to receive a new password.</p>
          <input
            type="email"
            className="form-control bg-dark text-white border-secondary"
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
            placeholder="Email address"
          />
        </Modal.Body>
        <Modal.Footer className="border-top border-secondary">
          <Button
            variant="secondary"
            onClick={() => setShowEmailModal(false)}
          >
            Close
          </Button>
          <Button 
            style={{backgroundColor: "#ff3030", borderColor: "#ff3030"}}
            onClick={submitForgotPassword}
          >
            Send Password
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}