import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Calendar = ({ selectedDate, setSelectedDate }) => {
  const today = new Date();
  const currentDay = today.getDate();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  const [monthOffset, setMonthOffset] = useState(0);

  const getMonthName = (month, year) => {
    const date = new Date(year, month);
    return date.toLocaleString("default", { month: "long", year: "numeric" });
  };

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (month, year) => {
    return new Date(year, month, 1).getDay(); // 0 = Sunday, 1 = Monday, etc.
  };

  const displayedMonth = (currentMonth + monthOffset) % 12;
  const displayedYear =
    currentYear + Math.floor((currentMonth + monthOffset) / 12);

  const daysInMonth = getDaysInMonth(displayedMonth, displayedYear);
  const firstDay = getFirstDayOfMonth(displayedMonth, displayedYear);

  const twoMonthsLater = new Date(today);
  twoMonthsLater.setMonth(today.getMonth() + 2);

  const handleDateClick = (day) => {
    setSelectedDate(new Date(displayedYear, displayedMonth, day));
  };

  const isDateDisabled = (day) => {
    const dateToCheck = new Date(displayedYear, displayedMonth, day);
    return (
      dateToCheck < new Date(today.setHours(0, 0, 0, 0)) ||
      dateToCheck > twoMonthsLater
    );
  };

  const navigateMonth = (direction) => {
    const maxMonthsAllowed = 2;
    if (direction === "prev" && monthOffset > 0) {
      setMonthOffset(monthOffset - 1);
    } else if (direction === "next" && monthOffset < maxMonthsAllowed) {
      setMonthOffset(monthOffset + 1);
    }
  };

  // Create calendar grid
  const calendarDays = [];
  
  // Add days from previous month to fill first week
  for (let i = 0; i < firstDay; i++) {
    calendarDays.push(null);
  }
  
  // Add days of current month
  for (let day = 1; day <= daysInMonth; day++) {
    calendarDays.push(day);
  }

  // Create weeks
  const weeks = [];
  let week = [];
  
  calendarDays.forEach((day, index) => {
    week.push(day);
    if (week.length === 7 || index === calendarDays.length - 1) {
      // Complete the last week if needed
      while (week.length < 7) {
        week.push(null);
      }
      weeks.push([...week]);
      week = [];
    }
  });

  const dayNames = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  return (
    <div className="calendar" style={{ 
      backgroundColor: "#1e1e1e", 
      color: "#ffffff", 
      padding: "10px", 
      borderRadius: "8px", 
      width: "100%",
      height: "350px",  // Fixed height to ensure consistent sizing
      display: "flex", 
      flexDirection: "column" 
    }}>
      <div className="month-header d-flex justify-content-between align-items-center mb-3">
        <button
          type="button"
          className="btn p-0 month-nav-btn"
          onClick={() => navigateMonth("prev")}
          disabled={monthOffset === 0}
          style={{color: monthOffset === 0 ? "#666" : "#ff3030"}}
        >
          <FaChevronLeft />
        </button>
        
        <h6 className="mb-0 fw-bold" style={{ color: "#ff3030" }}>
          {getMonthName(displayedMonth, displayedYear)}
        </h6>
        
        <button
          type="button"
          className="btn p-0 month-nav-btn"
          onClick={() => navigateMonth("next")}
          disabled={monthOffset === 2}
          style={{color: monthOffset === 2 ? "#666" : "#ff3030"}}
        >
          <FaChevronRight />
        </button>
      </div>

      <table className="calendar-table w-100">
        <thead>
          <tr>
            {dayNames.map((day) => (
              <th key={day} className="text-center py-1">
                <small style={{ color: "#ff3030" }}>{day}</small>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {weeks.map((week, weekIndex) => (
            <tr key={weekIndex}>
              {week.map((day, dayIndex) => (
                <td key={dayIndex} className="text-center p-1">
                  {day !== null ? (
                    <button
                      type="button"
                      className={`btn calendar-day p-0 d-flex align-items-center justify-content-center ${
                        selectedDate?.getDate() === day &&
                        selectedDate?.getMonth() === displayedMonth &&
                        selectedDate?.getFullYear() === displayedYear
                          ? "selected"
                          : ""
                      } ${
                        currentDay === day && 
                        currentMonth === displayedMonth &&
                        currentYear === displayedYear
                          ? "today"
                          : ""
                      }`}
                      onClick={() => !isDateDisabled(day) && handleDateClick(day)}
                      disabled={isDateDisabled(day)}
                      style={{
                        width: "25px", 
                        height: "25px",
                        margin: "2px",
                        backgroundColor: 
                          selectedDate?.getDate() === day &&
                          selectedDate?.getMonth() === displayedMonth &&
                          selectedDate?.getFullYear() === displayedYear
                            ? "#ff3030"
                            : "#242424",
                        color: "#ffffff",
                        borderRadius: "50%",
                        border: 
                          currentDay === day && 
                          currentMonth === displayedMonth &&
                          currentYear === displayedYear
                            ? "2px solid #ff3030"
                            : "1px solid #444",
                        opacity: isDateDisabled(day) ? 0.5 : 1
                      }}
                    >
                      {day}
                    </button>
                  ) : null}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Calendar;