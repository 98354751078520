import apiConfig from './apiConfig';
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FaMapMarkerAlt, FaTimes, FaStar, FaHeart } from "react-icons/fa";
import Swal from "sweetalert2";

export default function Favorites({ profileView = false }) {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [message, setMessage] = useState("");

  const parseJwt = (token) => {
    if (!token) return null;
    try {
      const base64Url = token.split(".")[1];
      const base64 = decodeURIComponent(
        atob(base64Url)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      return JSON.parse(base64);
    } catch (error) {
      console.error("Error parsing JWT", error);
      return null;
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    if (!token) {
      // Only show the alert if not in profile view
      if (!profileView) {
        Swal.fire({
          title: "Not Logged In",
          text: "Please log in to view your favorites",
          icon: "warning",
          confirmButtonColor: "#ff3030"
        }).then(() => {
          navigate("/authpage");
        });
      }
      return;
    }

    const decodedToken = parseJwt(token);
    if (decodedToken && decodedToken.email) {
      setUserEmail(decodedToken.email);
      fetchFavorites(decodedToken.email);
    } else {
      // Only show the alert if not in profile view
      if (!profileView) {
        Swal.fire({
          title: "Authentication Error",
          text: "Unable to verify your identity",
          icon: "error",
          confirmButtonColor: "#ff3030"
        }).then(() => {
          navigate("/authpage");
        });
      }
    }
  }, [navigate, profileView]);

  const fetchFavorites = async (email) => {
    try {

      
      setLoading(true);
      const response = await fetch(`${apiConfig.SPORTSPOT_MAIN_URL}/favorites/${email}`);


      if (response.ok) {
        const data = await response.json();
        setFavorites(data);
      } else {
        const error = await response.json();
        setMessage(error.detail || "Failed to fetch favorites");
      }
    } catch (err) {
      console.error("Error fetching favorites:", err);
      setMessage("Error fetching favorite grounds");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveFavorite = async (groundId) => {
    try {
      const result = await Swal.fire({
        title: "Remove Favorite?",
        text: "Are you sure you want to remove this ground from your favorites?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#ff3030",
        cancelButtonColor: "#333333",
        confirmButtonText: "Yes, remove it"
      });
      
      if (!result.isConfirmed) return;

      const response = await fetch(
        `${apiConfig.SPORTSPOT_MAIN_URL}/favorites/${userEmail}/${groundId}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        // Update the UI immediately
        setFavorites(favorites.filter(ground => ground.id !== groundId));
        
        Swal.fire({
          title: "Removed!",
          text: "Ground removed from favorites",
          icon: "success",
          confirmButtonColor: "#ff3030"
        });
      } else {
        const error = await response.json();
        Swal.fire({
          title: "Error",
          text: error.detail || "Failed to remove favorite",
          icon: "error",
          confirmButtonColor: "#ff3030"
        });
      }
    } catch (err) {
      console.error("Error removing favorite:", err);
      Swal.fire({
        title: "Error",
        text: "Error removing ground from favorites",
        icon: "error",
        confirmButtonColor: "#ff3030"
      });
    }
  };

  return (
    <div className="container py-3">
      {/* Only show header if not in profile view */}
      {!profileView && (
        <div className="row mb-4">
          <div className="col-12">
            <h1 className="text-center fw-bold mb-4">
              <FaHeart className="me-2" style={{color: "#ff3030"}} />
              My Favorite Grounds
            </h1>
            {message && (
              <div className="alert" style={{backgroundColor: "#2a2a2a", color: "#ff3030", border: "1px solid #ff3030"}}>
                {message}
              </div>
            )}
          </div>
        </div>
      )}

      {loading ? (
        <div className="text-center py-4">
          <div className="spinner-border" role="status" style={{color: "#ff3030"}}>
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : favorites.length > 0 ? (
        <div className="row g-4">
          {favorites.map((ground) => (
            <div className="col-md-6" key={ground.id}>
              <div className="card h-100" style={{backgroundColor: "#242424", border: "1px solid #333"}}>
                <div className="row g-0 h-100">
                  <div className="col-md-4 p-3 d-flex align-items-center justify-content-center">
                    <div 
                      className="rounded overflow-hidden" 
                      style={{width: "100%", height: "140px"}}
                    >
                      <img
                        src={ground.photo || "placeholder.png"}
                        alt={ground.name}
                        className="img-fluid h-100 w-100 object-fit-cover"
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body d-flex flex-column h-100">
                      <div className="d-flex justify-content-between align-items-start">
                        <h5 
                          className="card-title mb-2 fw-bold"
                          style={{cursor: "pointer"}}
                          onClick={() => navigate("/ground", { state: { ground } })}
                        >
                          {ground.name}
                        </h5>
                        <button
                          className="btn p-0 text-danger"
                          onClick={() => handleRemoveFavorite(ground.id)}
                          style={{color: "#ff3030"}}
                        >
                          <FaTimes />
                        </button>
                      </div>
                      
                      <div className="d-flex align-items-center mb-2">
                        <FaStar style={{color: "#ff3030"}} className="me-1" />
                        <span className="me-2">{ground.rating || "N/A"}</span>
                        <span className="text-muted small">({ground.total_ratings || 0} reviews)</span>
                      </div>
                      
                      <div className="d-flex align-items-start mb-3">
                        <FaMapMarkerAlt className="mt-1 me-2" style={{color: "#ff3030"}} />
                        <p className="card-text mb-0 small">{ground.address}</p>
                      </div>
                      
                      <div className="mt-auto">
                        <button 
                          className="btn w-100 py-2"
                          style={{backgroundColor: "#ff3030"}}
                          onClick={() => navigate("/ground", { state: { ground } })}
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-3">
          <div 
            className="p-4 rounded mx-auto" 
            style={{backgroundColor: "#242424", maxWidth: "600px"}}
          >
            <div className="mb-3">
              <FaHeart style={{color: "#666", fontSize: "48px"}} />
            </div>
            <h4 className="mb-3">No Favorite Grounds Yet</h4>
            <p className="text-muted mb-4">
              You haven't added any grounds to your favorites list. 
              Browse grounds and click the heart icon to add favorites.
            </p>
            <button 
              className="btn px-4 py-2"
              style={{backgroundColor: "#ff3030"}}
              onClick={() => navigate("/")}
            >
              Explore Grounds
            </button>
          </div>
        </div>
      )}
    </div>
  );
}