import apiConfig from './apiConfig';
import React, { useState, useEffect } from "react";
import Calendar from "./Calender";
import { FaCalendarAlt, FaClock, FaPhone, FaCheckCircle } from "react-icons/fa";
import Swal from "sweetalert2";

const Booking = ({
  selectedPitch,
  onDurationChange,
  selectedGroundID,
  selectedGroundName,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState("60 Mins");
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isBooked, setIsBooked] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [error, setError] = useState("");
  const [availableSlots, setAvailableSlots] = useState({
    "60 Mins": [],
    "90 Mins": [],
  });
  const [loadingSlots, setLoadingSlots] = useState(false);

  const parseJwt = (token) => {
    if (!token) return null;
    try {
      const base64Url = token.split(".")[1];
      const base64 = decodeURIComponent(
        atob(base64Url)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      return JSON.parse(base64);
    } catch (error) {
      console.error("Error parsing JWT", error);
      return null;
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    if (token) {
      const decodedToken = parseJwt(token);
      if (decodedToken && decodedToken.email) {
        setUserEmail(decodedToken.email);
      } else {
        setError("Unable to decode token or email is missing in token");
      }
    } else {
      setError("If you want to make a booking, please log in!");
    }
  }, []);

  const [refreshSlots, setRefreshSlots] = useState(0); // Add refresh trigger

  // Extract fetchSlots to standalone function
  const fetchSlots = async () => {
    if (selectedDate && selectedGroundID && selectedPitch?.name) {
      setLoadingSlots(true);
      try {
        const formattedDate =
          selectedDate.getFullYear() +
          "-" +
          String(selectedDate.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(selectedDate.getDate()).padStart(2, "0");

        const response = await fetch(
          `${apiConfig.SPORTSPOT_MAIN_URL}/ground_booking/slots/${selectedGroundID}/${encodeURIComponent(
            selectedPitch.name
          )}/${formattedDate}`
        );

        if (!response.ok) throw new Error("Failed to fetch slots");

        const data = await response.json();

        const convertSlots = (slotData) => {
          return {
            "60 Mins": slotData["60"]
              ? slotData["60"].map((time) => time.substring(11, 16))
              : [],
            "90 Mins": slotData["90"]
              ? slotData["90"].map((time) => time.substring(11, 16))
              : [],
          };
        };

        setAvailableSlots(convertSlots(data));

      } catch (error) {
        setError("Failed to load available slots");
      } finally {
        setLoadingSlots(false);
      }
    }
  };

  useEffect(() => {
    const debounceTimer = setTimeout(fetchSlots, 300);
    return () => clearTimeout(debounceTimer);
  }, [selectedDate, selectedGroundID, selectedPitch?.name, refreshSlots]); // Add refresh trigger

  const validatePhoneNumber = () => {
    const phoneRegex = /^[0-9]{11}$/;
    if (!phoneRegex.test(phoneNumber)) {
      setPhoneError("Please enter a valid 11-digit phone number.");
      return false;
    }
    setPhoneError("");
    return true;
  };

  const handleDurationClick = (duration) => {
    setSelectedDuration(duration);
    onDurationChange(duration);
    setSelectedSlot(null);
  };

  const handleSlotClick = (slot) => {
    setSelectedSlot(slot);
  };

  const handleBooking = async () => {
    if (!selectedPitch) {
      setError("Please select a pitch for booking.");
      return;
    }

    if (!selectedDuration) {
      setError("Please select a duration for booking.");
      return;
    }

    if (!selectedSlot) {
      setError("Please select a slot for booking.");
      return;
    }

    if (!validatePhoneNumber()) return;

    const dateToBook = selectedDate || new Date();
    const formatDateTime = (date, time) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day} ${time}:00`;
    };

    const start_time = formatDateTime(dateToBook, selectedSlot);
    const duration_seconds = selectedDuration === "60 Mins" ? 60 * 60 : 90 * 60;

    const formatBookingDate = (startTimeString) => {
      const [datePart] = startTimeString.split(" ");
      const [year, month, day] = datePart.split("-");
      return `${year.slice(-2)}-${month}-${day}`;
    };

    const bookingDetails = {
      pitch_name: selectedPitch.name,
      ground_id: selectedGroundID,
      user_email: userEmail,
      user_contact_no: phoneNumber,
      start_time: start_time,
      duration: duration_seconds,
      payment_status: "pending",
      booking_date: formatBookingDate(start_time), // Use the new formatter
    };

    try {
      const response = await fetch(`${apiConfig.SPORTSPOT_MAIN_URL}/ground_booking/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bookingDetails),
      });

      const result = await response.json();
      if (response.ok) {
        setRefreshSlots((prev) => prev + 1);
        
        // Show success notification with Swal
        await Swal.fire({
          title: "Booking Successful!",
          text: "Your booking has been confirmed.",
          icon: "success",
          confirmButtonColor: "#ff3030"
        });

        // Send email confirmation
        const secondResponse = await fetch(
          `${apiConfig.SPORTSPOT_AUTH_URL}/bookings/${userEmail}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              subject: "Your Booking Details",
              body: `Thanks for booking! You have booked pitch \"${bookingDetails.pitch_name}\" of ground \"${selectedGroundName}\". Your time starts at ${selectedSlot} for ${selectedDuration} on ${bookingDetails.booking_date}.`,
            }),
          }
        );

        if (secondResponse.ok) {
          await Swal.fire({
            title: "Email Sent!",
            text: "Booking confirmation has been sent to your email.",
            icon: "success",
            confirmButtonColor: "#ff3030"
          });
        } else {
          await Swal.fire({
            title: "Email Not Sent",
            text: "Your booking is confirmed, but there was an issue sending the confirmation email.",
            icon: "warning",
            confirmButtonColor: "#ff3030"
          });
        }
        
        // Reset selected slot
        setSelectedSlot(null);
      } else {
        await Swal.fire({
          title: "Booking Failed",
          text: result.detail || "Failed to make your booking. Please try again.",
          icon: "error",
          confirmButtonColor: "#ff3030"
        });
      }
    } catch (error) {
      setError("Request Denied! Please log in to make a booking");
      Swal.fire({
        title: "Error",
        text: "Login required to make a booking.",
        icon: "error",
        confirmButtonColor: "#ff3030"
      });
    }
  };

  return (
    <div className="booking-container" style={{ 
      backgroundColor: "#242424", 
      borderRadius: "8px", 
      padding: "15px" 
    }}>
      <div className="row g-3">
        <div className="col-12">
          <div className="calendar-container" style={{ 
            backgroundColor: "#1e1e1e", 
            borderRadius: "8px", 
            padding: "15px" 
          }}>
            <h5 className="mb-3 d-flex align-items-center text-white">
              <FaCalendarAlt className="me-2" style={{color: "#ff3030"}} />
              Select Date
            </h5>
            <Calendar 
              selectedDate={selectedDate} 
              setSelectedDate={setSelectedDate} 
            />
          </div>
        </div>
        
        <div className="col-12">
          <div className="contact-container" style={{ 
            backgroundColor: "#1e1e1e", 
            borderRadius: "8px", 
            padding: "15px", 
            marginTop: "15px" 
          }}>
            <h5 className="mb-3 d-flex align-items-center text-white">
              <FaPhone className="me-2" style={{color: "#ff3030"}} />
              Contact Information
            </h5>
            <div className="mb-3">
              <label className="form-label text-white">Phone Number *</label>
              <input
                type="text"
                className="form-control bg-dark text-white"
                value={phoneNumber}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, "");
                  if (numericValue.length <= 11) {
                    setPhoneNumber(numericValue);
                  }
                }}
                placeholder="Your 11-digit phone number"
                maxLength="11"
              />
              {phoneError && <div className="text-danger small mt-1">{phoneError}</div>}
            </div>
            
            <div className="mb-3">
              <label className="form-label d-flex align-items-center">
                <FaClock className="me-2" style={{color: "#ff3030"}} />
                Select Duration
              </label>
              <div className="d-flex">
                <button
                  type="button"
                  className={`btn flex-grow-1 me-2 ${selectedDuration === "60 Mins" ? "duration-selected" : ""}`}
                  onClick={() => handleDurationClick("60 Mins")}
                  style={{
                    backgroundColor: selectedDuration === "60 Mins" ? "#ff3030" : "#242424",
                    borderColor: selectedDuration === "60 Mins" ? "#ff3030" : "#444",
                    color: selectedDuration === "60 Mins" ? "black" : "white", // Set color to white when not selected
                  }}
                >
                  60 Mins
                </button>
                <button
                  type="button"
                  className={`btn flex-grow-1 ${selectedDuration === "90 Mins" ? "duration-selected" : ""}`}
                  onClick={() => handleDurationClick("90 Mins")}
                  style={{
                    backgroundColor: selectedDuration === "90 Mins" ? "#ff3030" : "#242424",
                    borderColor: selectedDuration === "90 Mins" ? "#ff3030" : "#444",
                    color: selectedDuration === "90 Mins" ? "black" : "white", // Set color to white when not selected
                  }}
                >
                  90 Mins
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mb-4">
        <div className="booking-section rounded p-3" style={{backgroundColor: "#2a2a2a"}}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="mb-0">Available Time Slots</h5>
            <button 
              type="button" 
              className="btn btn-sm refresh-btn"
              onClick={() => setRefreshSlots(prev => prev + 1)}
              style={{color: "#ff3030", border: "1px solid #ff3030"}}
              disabled={!selectedDate || !selectedPitch}
            >
              Refresh
            </button>
          </div>
          
            {!selectedDate ? (
              <div className="text-center p-4 text-muted">
                <div>Please select a date to view available slots</div>
              </div>
            ) : loadingSlots ? (
              <div className="text-center p-4">
                <div className="spinner-border" role="status" style={{color: "#ff3030"}}>
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {availableSlots[selectedDuration].length > 0 ? (
                  <div className="time-slots-container" style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    border: "1px solid #333",
                    borderRadius: "8px",
                    padding: "10px"
                  }}>
                    <div className="row g-2">
                      {availableSlots[selectedDuration].map((slot, index) => {
                        // Format the time slot as a range
                        const startTime = slot;
                        const [hours, minutes] = startTime.split(':').map(num => parseInt(num));
                        
                        // Calculate end time based on duration
                        const endHours = hours + (selectedDuration === "60 Mins" ? 1 : 1.5);
                        const endMinutes = selectedDuration === "60 Mins" ? minutes : (minutes + 30) % 60;
                        const endHoursFinal = endMinutes === 30 ? Math.floor(endHours) : endHours;
                        
                        // Format end time
                        const formattedEndTime = `${Math.floor(endHoursFinal)}:${String(endMinutes).padStart(2, '0')}`;
                        
                        // Create time range display
                        const timeRange = `${startTime} - ${formattedEndTime}`;
                        
                        return (
                          <div className="col-12 mb-2" key={index}>
                            <button
                              type="button"
                              className={`btn time-slot w-100 text-start ${selectedSlot === slot ? "selected" : ""}`}
                              onClick={() => handleSlotClick(slot)}
                              style={{
                                backgroundColor: selectedSlot === slot ? "#ff3030" : "#242424",
                                borderColor: selectedSlot === slot ? "#ff3030" : "#444",
                                color: "white",
                                padding: "10px 15px"
                              }}
                            >
                              {timeRange}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="text-center p-4 text-muted">
                    <div>No time slots available for the selected date and duration</div>
                  </div>
                )}
              </>
            )}
        </div>
      </div>
      
      <div className="booking-summary mb-4 p-3 rounded" style={{backgroundColor: "#242424"}}>
        <h5 className="mb-3">Booking Summary</h5>
        <div className="row mb-3">
          <div className="col-md-4 mb-2 mb-md-0">
            <div className="summary-item">
              <div className="text-muted small">Pitch</div>
              <div>{selectedPitch?.name || 'Not selected'}</div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-md-0">
            <div className="summary-item">
              <div className="text-muted small">Date</div>
              <div>{selectedDate ? selectedDate.toLocaleDateString() : 'Not selected'}</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="summary-item">
              <div className="text-muted small">Time</div>
              <div>{selectedSlot || 'Not selected'}</div>
            </div>
          </div>
        </div>
        
        <button
          type="button"
          className="btn w-100 py-2"
          onClick={handleBooking}
          disabled={!selectedPitch || !selectedDate || !selectedSlot || !phoneNumber}
          style={{
            backgroundColor: (!selectedPitch || !selectedDate || !selectedSlot || !phoneNumber) ? "#666" : "#ff3030",
            color: "white"
          }}
        >
          {isBooked ? (
            <span className="d-flex align-items-center justify-content-center">
              <FaCheckCircle className="me-2" /> Booked
            </span>
          ) : (
            "Confirm Booking"
          )}
        </button>
      </div>
    </div>
  );
};

export default Booking;