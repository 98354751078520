import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import AuthPage from "./Components/AuthPage";
import Home from "./Components/Home";
import React, { useState } from "react";
import Footer from "./Components/Footer";
import Ground from "./Components/Ground";
import UpcomingBookings from "./Components/UpcomingBookings";
import Favorites from "./Components/Favorites";
import Help from "./Components/Help";
import ProfilePage from "./Components/ProfilePage";

function App() {
  const [authMode, setAuthMode] = useState("SignUp");

  const toggleAuthMode = (mode) => {
    setAuthMode(mode);
  };

  return (
    <>
      <Router>
        <div className="app-container">
          <header>
            <Navbar 
              title="Sport Spot" 
              authMode={authMode} 
              toggleAuthMode={toggleAuthMode} 
            />
          </header>
          <main className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route 
                path="/authpage" 
                element={
                  <AuthPage
                    authMode={authMode}
                    toggleAuthMode={toggleAuthMode}
                  />
                } 
              />
              <Route path="/ground" element={<Ground />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/upcoming-bookings" element={<UpcomingBookings />} />
              <Route path="/favorites" element={<Favorites />} />
              <Route path="/help" element={<Help />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;