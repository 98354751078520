import apiConfig from './apiConfig';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCalendarAlt, FaClock, FaMapMarkerAlt, FaTicketAlt } from "react-icons/fa";
import Swal from "sweetalert2";

export default function UpcomingBookings({ profileView = false }) {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [message, setMessage] = useState("");

  const parseJwt = (token) => {
    if (!token) return null;
    try {
      const base64Url = token.split(".")[1];
      const base64 = decodeURIComponent(
        atob(base64Url)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      return JSON.parse(base64);
    } catch (error) {
      console.error("Error parsing JWT", error);
      return null;
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    if (!token) {
      // Only show the alert if not in profile view
      if (!profileView) {
        Swal.fire({
          title: "Not Logged In",
          text: "Please log in to view your bookings",
          icon: "warning",
          confirmButtonColor: "#ff3030"
        }).then(() => {
          navigate("/authpage");
        });
      }
      return;
    }

    const decodedToken = parseJwt(token);
    if (decodedToken && decodedToken.email) {
      setUserEmail(decodedToken.email);
      fetchBookings(decodedToken.email);
    } else {
      // Only show the alert if not in profile view
      if (!profileView) {
        Swal.fire({
          title: "Authentication Error",
          text: "Unable to verify your identity",
          icon: "error",
          confirmButtonColor: "#ff3030"
        }).then(() => {
          navigate("/authpage");
        });
      }
    }
  }, [navigate, profileView]);

  const fetchBookings = async (email) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${apiConfig.SPORTSPOT_MAIN_URL}/upcoming_bookings/upcoming/${email}`
      );

      if (response.ok) {
        const data = await response.json();
        setBookings(data);
      } else {
        const error = await response.json();
        setMessage(error.detail || "Failed to fetch upcoming bookings");
      }
    } catch (err) {
      console.error("Error fetching bookings:", err);
      setMessage("Error fetching upcoming bookings");
    } finally {
      setLoading(false);
    }
  };

  // Format date for display: "Monday, January 15, 2024"
  const formatDate = (dateStr) => {
    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    return new Date(dateStr).toLocaleDateString(undefined, options);
  };

  // Format time for display: "5:30 PM"
  const formatTime = (timeStr) => {
    const date = new Date(timeStr);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // Group bookings by date
  const groupBookingsByDate = () => {
    const groups = {};
    
    bookings.forEach(booking => {
      const dateKey = booking.booking_date.split('T')[0];
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(booking);
    });
    
    // Sort dates in ascending order
    return Object.keys(groups)
      .sort()
      .map(date => ({
        date,
        formattedDate: formatDate(date),
        bookings: groups[date].sort((a, b) => 
          new Date(a.start_time) - new Date(b.start_time)
        )
      }));
  };

  // Get status based on booking time
  const getBookingStatus = (startTime) => {
    const now = new Date();
    const bookingTime = new Date(startTime);
    
    if (bookingTime < now) {
      return { label: "Completed", color: "#666" };
    }
    
    // If booking is within 24 hours
    const timeDiff = bookingTime.getTime() - now.getTime();
    const hoursDiff = timeDiff / (1000 * 60 * 60);
    
    if (hoursDiff <= 24) {
      return { label: "Upcoming Soon", color: "#ff3030" };
    }
    
    return { label: "Scheduled", color: "#009688" };
  };

  return (
    <div className="container py-3">
      {/* Only show header if not in profile view */}
      {!profileView && (
        <div className="row mb-4">
          <div className="col-12">
            <h1 className="text-center fw-bold mb-4">
              <FaTicketAlt className="me-2" style={{color: "#ff3030"}} />
              My Upcoming Bookings
            </h1>
            {message && (
              <div className="alert" style={{backgroundColor: "#2a2a2a", color: "#ff3030", border: "1px solid #ff3030"}}>
                {message}
              </div>
            )}
          </div>
        </div>
      )}

      {loading ? (
        <div className="text-center py-5">
          <div className="spinner-border" role="status" style={{color: "#ff3030"}}>
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : bookings.length > 0 ? (
        <div className="booking-groups">
          {groupBookingsByDate().map(group => (
            <div key={group.date} className="booking-group mb-4">
              <div 
                className="date-header d-flex align-items-center p-3 rounded-top" 
                style={{backgroundColor: "#2a2a2a"}}
              >
                <FaCalendarAlt className="me-2" style={{color: "#ff3030"}} />
                <h5 className="mb-0 fw-bold">{group.formattedDate}</h5>
              </div>
              
              <div className="booking-list rounded-bottom" style={{backgroundColor: "#242424"}}>
                {group.bookings.map((booking, index) => {
                  const status = getBookingStatus(booking.start_time);
                  return (
                    <div 
                      key={`${booking.pitch_name}-${booking.start_time}`} 
                      className={`booking-item p-3 ${
                        index !== group.bookings.length - 1 ? 'border-bottom' : ''
                      }`}
                      style={{borderColor: "#333"}}
                    >
                      <div className="row align-items-center">
                        <div className="col-md-6 mb-3 mb-md-0">
                          <h5 className="mb-2">{booking.ground_name}</h5>
                          <div className="d-flex align-items-center text-muted">
                            <span className="badge me-2" style={{backgroundColor: "#333"}}>
                              {booking.pitch_name}
                            </span>
                            <span className="badge" style={{backgroundColor: status.color}}>
                              {status.label}
                            </span>
                          </div>
                        </div>
                        
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-sm-6 mb-2 mb-sm-0">
                              <div className="d-flex align-items-center">
                                <FaClock className="me-2" style={{color: "#ff3030"}} />
                                <div>
                                  <div className="small text-muted">Time</div>
                                  <div>{formatTime(booking.start_time)}</div>
                                </div>
                              </div>
                            </div>
                            
                            <div className="col-sm-6">
                              <div className="d-flex align-items-center">
                                <FaTicketAlt className="me-2" style={{color: "#ff3030"}} />
                                <div>
                                  <div className="small text-muted">Duration</div>
                                  <div>{booking.duration}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-3">
          <div 
            className="p-4 rounded mx-auto" 
            style={{backgroundColor: "#242424", maxWidth: "600px"}}
          >
            <div className="mb-3">
              <FaCalendarAlt style={{color: "#666", fontSize: "48px"}} />
            </div>
            <h4 className="mb-3">No Upcoming Bookings</h4>
            <p className="text-muted mb-4">
              You don't have any upcoming bookings scheduled. 
              Browse grounds and book your favorite sports venue.
            </p>
            <button 
              className="btn px-4 py-2"
              style={{backgroundColor: "#ff3030"}}
              onClick={() => navigate("/")}
            >
              Book Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
}