import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Custom red marker icon
const redMarkerIcon = new L.Icon({
  iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

// Styling for zoom controls
const mapStyles = `
  .leaflet-control-zoom {
    background-color: black;
    border: 2px solid rgba(255,255,255,0.2);
    border-radius: 4px;
  }
  .leaflet-control-zoom a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background-color: black;
    color: #ff3030;
    text-align: center;
    text-decoration: none;
    display: block;
    border: 1px solid #ff3030;
  }
  .leaflet-control-zoom a:hover {
    background-color: #222;
    color: white;
  }
  .custom-popup .leaflet-popup-content-wrapper {
    background-color: #121212;
    color: white;
    border-radius: 8px;
    border: 1px solid #ff3030;
  }
  .custom-popup .leaflet-popup-tip {
    background-color: #121212;
    border: 1px solid #ff3030;
  }
`;

const Map1 = ({ 
  onLocationSelect, 
  initialLat, 
  initialLng 
}) => {
  const [position] = useState([initialLat, initialLng]);
  const [isRedirectReady, setIsRedirectReady] = useState(false);

  const handleMarkerClick = () => {
    if (!isRedirectReady) {
      setIsRedirectReady(true);
    } else {
      // Open Google Maps with the location
      window.open(`https://www.google.com/maps?q=${initialLat},${initialLng}`, '_blank');
      setIsRedirectReady(false);
    }
  };

  return (
    <>
      <style>{mapStyles}</style>
      <MapContainer
        center={position}
        zoom={13}
        style={{ 
          width: '100%', 
          height: '400px', 
          borderRadius: '8px' 
        }}
        zoomControl={false}
        scrollWheelZoom={false}
        doubleClickZoom={false}
        dragging={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker 
          position={position} 
          icon={redMarkerIcon}
          eventHandlers={{
            click: handleMarkerClick
          }}
        >
          <Popup className="custom-popup">
            <div style={{ backgroundColor: '#121212', color: 'white', padding: '8px' }}>
              {isRedirectReady ? 'Click again to open in Google Maps' : 'Click again to open in Google Maps'}
            </div>
          </Popup>
        </Marker>
      </MapContainer>
    </>
  );
};

export default Map1;