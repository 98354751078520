import React from "react";
import { FaMapMarkerAlt, FaSearch } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";

function SearchBar({ value, onChange }) {
  return (
    <div className="search-bar d-flex align-items-center" style={{
      backgroundColor: "#242424",
      borderRadius: "30px",
      border: "1px solid #ff3030",
      padding: "0px",
      height: "45px",
      minWidth: "250px",
      overflow: "hidden"
    }}>
      <div className="search-item d-flex align-items-center px-3 flex-grow-1">
        <FaMapMarkerAlt style={{ color: "#ff3030" }} className="me-2" />
        <input
          type="text"
          className="form-control border-0 bg-transparent shadow-none"
          placeholder="Enter Location"
          value={value}
          onChange={onChange}
          style={{ 
            color: "white",
            caretColor: "white"
          }}
        />
      </div>
      <button 
        className="btn d-flex align-items-center justify-content-center"
        style={{ 
          width: "45px", 
          height: "45px", 
          backgroundColor: "#ff3030", 
          color: "white",
          border: "none",
          borderRadius: "0 30px 30px 0"
        }}
      >
        <FaSearch size={14} />
      </button>
    </div>
  );
}

export default SearchBar;