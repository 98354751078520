import apiConfig from './apiConfig';
import React, { useState, useCallback } from "react";
import { FaHeadset, FaEnvelope, FaPaperPlane, FaQuestionCircle } from "react-icons/fa";
import Swal from "sweetalert2";

export default function Help() {
  const [formData, setFormData] = useState({
    subject: "",
    body: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // Loading state

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    },
    [formData]
  );

  const validate = () => {
    const newErrors = {};
    if (!formData.subject) newErrors.subject = "Subject is required";
    if (!formData.body) newErrors.body = "Message is required";
    return newErrors;
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const formErrors = validate();
      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        return;
      }

      setErrors({});
      setIsSubmitting(true);

      try {

        const response = await fetch(`${apiConfig.GROUND_OWNER_URL}/help/`, {

          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          throw new Error("Failed to send the message. Please try again.");
        }

        await Swal.fire({
          title: "Message Sent!",
          text: "Your request has been sent to our team. We'll get back to you soon.",
          icon: "success",
          confirmButtonColor: "#ff3030"
        });

        setFormData({
          subject: "",
          body: "",
        });
      } catch (error) {
        console.error("Error sending help request:", error);
        Swal.fire({
          title: "Error",
          text: "Failed to send your message. Please try again later.",
          icon: "error",
          confirmButtonColor: "#ff3030"
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [formData]
  );

  // FAQ data
  const faqs = [
    {
      question: "How do I book a sports venue?",
      answer: "To book a venue, browse the available grounds, select the one you like, choose your preferred date, time slot, and pitch type, then confirm your booking. You'll receive a confirmation email with all details."
    },
    {
      question: "What payment methods are accepted?",
      answer: "Currently we support cash payments on arrival. Online payment options will be coming soon!"
    },
    {
      question: "Can I reschedule or cancel my booking?",
      answer: "Yes, you can cancel your booking up to 24 hours before your scheduled time. To reschedule, please cancel your current booking and make a new one."
    },
    {
      question: "How do I find the nearest venues?",
      answer: "Use the map view on our homepage or enter your location in the search bar to find venues near you."
    },
    {
      question: "I'm having technical issues with the app. What should I do?",
      answer: "Please send us a detailed description of the issue using the contact form below. Our technical team will assist you as soon as possible."
    }
  ];

  return (
    <div className="container py-5">
      <div className="row mb-5">
        <div className="col-12 text-center">
          <h1 className="fw-bold mb-4">
            <FaHeadset className="me-2" style={{ color: "#ff3030" }} />
            Help & Support
          </h1>
          <p className="lead">We're here to help you with any questions or issues</p>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-12">
          <div className="p-4 rounded" style={{ backgroundColor: "#242424" }}>
            <h3 className="mb-4 fw-bold">
              <FaQuestionCircle className="me-2" style={{ color: "#ff3030" }} />
              Frequently Asked Questions
            </h3>

            <div className="accordion" id="faqAccordion">
              {faqs.map((faq, index) => (
                <div className="accordion-item mb-3" key={index} style={{ backgroundColor: "#2a2a2a", color: "#fff" }}>
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="true"
                      aria-controls={`collapse${index}`}
                      style={{ backgroundColor: "#333", color: "#fff" }}
                    >
                      {faq.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">{faq.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 mx-auto">
          <div className="p-4 rounded" style={{ backgroundColor: "#242424" }}>
            <h3 className="mb-4 fw-bold">
              <FaEnvelope className="me-2" style={{ color: "#ff3030" }} />
              Contact Us
            </h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Subject</label>
                <input
                  type="text"
                  name="subject"
                  className={`form-control ${errors.subject ? "is-invalid" : ""}`}
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Enter subject"
                />
                {errors.subject && <div className="invalid-feedback">{errors.subject}</div>}
              </div>

              <div className="mb-3">
                <label className="form-label">Message</label>
                <textarea
                  name="body"
                  className={`form-control ${errors.body ? "is-invalid" : ""}`}
                  value={formData.body}
                  onChange={handleChange}
                  rows="4"
                  placeholder="Enter your message"
                />
                {errors.body && <div className="invalid-feedback">{errors.body}</div>}
              </div>

              <button type="submit" className="btn btn-danger w-100" disabled={isSubmitting}>
                {isSubmitting ? "Sending..." : (
                  <>
                    <FaPaperPlane className="me-2" />
                    Send Message
                  </>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
