import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Link } from "react-router-dom";

// Custom Blue Marker for Grounds
const blueMarkerIcon = new L.Icon({
  iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

// Custom Red Marker for User's Location
const redMarkerIcon = new L.Icon({
  iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

// Styling for map controls and popups
const mapStyles = `
  .leaflet-control-zoom {
    background-color: black;
    border: 2px solid rgba(255,255,255,0.2);
    border-radius: 4px;
  }
  .leaflet-control-zoom a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background-color: black;
    color: #ff3030;
    text-align: center;
    text-decoration: none;
    display: block;
    border: 1px solid #ff3030;
  }
  .leaflet-control-zoom a:hover {
    background-color: #222;
    color: white;
  }
  .custom-popup .leaflet-popup-content-wrapper {
    background-color: #121212;
    color: white;
    border-radius: 8px;
    border: 1px solid #ff3030;
  }
  .custom-popup .leaflet-popup-tip {
    background-color: #121212;
    border: 1px solid #ff3030;
  }
`;

const Map = ({ onLocationSelect, initialGrounds }) => {
  const [position, setPosition] = useState([initialGrounds[0]?.latitude || 31.5204, initialGrounds[0]?.longitude || 74.3587]);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation([latitude, longitude]);
          setPosition([latitude, longitude]);
        },
        () => {
          console.warn("Geolocation permission denied. Using default location.");
        }
      );
    }
  }, []);

  const MoveToUserLocation = () => {
    const map = useMap();
    useEffect(() => {
      if (userLocation) {
        map.setView(userLocation, 13);
      }
    }, [userLocation, map]);
    return null;
  };

  return (
    <>
      <style>{mapStyles}</style>
      <div 
        className="map-wrapper" 
        style={{ 
          width: '100%', 
          height: '50vh',
          minHeight: '500px',
          borderRadius: '10px'
        }}
      >
        <MapContainer
          center={position}
          zoom={13}
          style={{ 
            width: '100%', 
            height: '100%' 
          }}
          scrollWheelZoom={true}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />

          {userLocation && (
            <Marker position={userLocation} icon={redMarkerIcon}>
              <Popup className="custom-popup">
                <div style={{ backgroundColor: '#121212', color: 'white', padding: '8px' }}>
                  You are here
                </div>
              </Popup>
            </Marker>
          )}

          <MoveToUserLocation />
          
          {initialGrounds.map((ground, index) => (
            <Marker
              key={index}
              position={[ground.latitude, ground.longitude]}
              icon={blueMarkerIcon}
            >
              <Popup className="custom-popup">
                <div style={{ 
                  backgroundColor: '#121212', 
                  color: 'white',
                  padding: '12px',
                  borderRadius: '4px'
                }}>
                  <Link
                    to={`/ground`}
                    state={{ ground }}
                    style={{ 
                      textDecoration: "none", 
                      color: "#ff3030", 
                      fontWeight: "bold",
                      display: "block",
                      marginBottom: '5px'
                    }}
                  >
                    {ground.name}
                  </Link>
                  <p 
                    className="mb-0" 
                    style={{ 
                      color: "white", 
                      fontSize: '0.9rem' 
                    }}
                  >
                    {ground.address}
                  </p>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </>
  );
};

export default Map;