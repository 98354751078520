import apiConfig from './apiConfig';
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaMapMarkerAlt, FaHeart, FaRegHeart, FaStar, FaClock, FaInfoCircle } from "react-icons/fa";
import axios from "axios";
import Booking from "./Booking";
import Map1 from "./Map1";
import Review from "./Review";
import Swal from 'sweetalert2';

export default function Ground() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userEmail, setUserEmail] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  const [groundData, setGroundData] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [selectedPitch, setSelectedPitch] = useState("");
  const [images, setImages] = useState([]);
  const [errors, setError] = useState("");
  const [logErrors, setLogError] = useState("");
  const [pitches, setPitches] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [pricePer60Mins, setPricePer60Mins] = useState(null);
  const [pricePer90Mins, setPricePer90Mins] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    if (!token) {
      // Redirect to login/signup page if not authenticated
      Swal.fire({
        title: "Authentication Required",
        text: "Please log in to view ground details",
        icon: "warning",
        confirmButtonColor: "#ff3030"
      }).then(() => {
        navigate("/authpage");
      });
      return;
    }
  
    if (location.state && location.state.ground) {
      setGroundData(location.state.ground);
      fetchImages(location.state.ground.id);
      fetchPitches(location.state.ground.id);
      fetchReviews(location.state.ground.id);
      fetchFacilities(location.state.ground.id);
      fetchEquipment(location.state.ground.id);
    }
  }, [location.state, navigate]);

  useEffect(() => {
    if (location.state && location.state.ground) {
      setGroundData(location.state.ground);
      fetchImages(location.state.ground.id);
      fetchPitches(location.state.ground.id);
      fetchReviews(location.state.ground.id);
      fetchFacilities(location.state.ground.id);
      fetchEquipment(location.state.ground.id);
    }
  }, [location.state]);

  const parseJwt = (token) => {
    if (!token) return null;
    try {
      const base64Url = token.split(".")[1];
      const base64 = decodeURIComponent(
        atob(base64Url)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      return JSON.parse(base64);
    } catch (error) {
      console.error("Error parsing JWT", error);
      return null;
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
      const decodedToken = parseJwt(token);
      if (decodedToken?.email) {
        setUserEmail(decodedToken.email);
      }
    }
  }, []);

  useEffect(() => {
    if (userEmail && groundData?.id) {
      checkFavoriteStatus();
    }
  }, [userEmail, groundData]);

  const checkFavoriteStatus = async () => {
    try {
      const response = await axios.get(
        `${apiConfig.SPORTSPOT_MAIN_URL}/favorites/check_fav/${userEmail}/${groundData.id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setIsFavorite(response.data === true);
    } catch (error) {
      console.error("Error checking favorite status:", error);
      setIsFavorite(false);
    } finally {
      setLoading(false);
    }
  };

  const toggleFavorite = async () => {
    if (!authToken) {
      setLogError("Please login to manage favorites");
      return;
    }

    const wasFavorite = isFavorite;
    // Optimistic UI update
    setIsFavorite(!wasFavorite);

    try {
      if (wasFavorite) {
        // Remove from favorites
        await axios.delete(
          `${apiConfig.SPORTSPOT_MAIN_URL}/favorites/${userEmail}/${groundData.id}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
      } else {
        // Add to favorites
        await axios.post(
          `${apiConfig.SPORTSPOT_MAIN_URL}/favorites/${userEmail}/${groundData.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
      }
      await checkFavoriteStatus();
    } catch (error) {
      setIsFavorite(wasFavorite);
      const errorMsg = error.response?.data?.detail || "Operation failed";
      setError(errorMsg);
    }
  };

  const handlePitchClick = (pitch) => {
    setSelectedPitch(pitch);
  };

  const fetchImages = async (groundId) => {
    try {
      const response = await axios.get(
        `${apiConfig.SPORTSPOT_MAIN_URL}/ground_list/groundimages/${groundId}`
      );
      const formattedImages = response.data.images.map(
        (img) => `data:image/jpeg;base64,${img}`
      );
      setImages(formattedImages);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const fetchPitches = async (groundId) => {
    try {
      const response = await axios.get(
        `${apiConfig.SPORTSPOT_MAIN_URL}/ground_list/ground_detail/pitches/${groundId}`
      );
      const pitchesData = response.data;
      setPitches(pitchesData);

      if (pitchesData.length > 0) {
        setPricePer60Mins(pitchesData[0].price_per_60mins);
        setPricePer90Mins(pitchesData[0].price_per_90mins || null);
        setSelectedPrice(pitchesData[0].price_per_60mins);
      }
    } catch (error) {
      console.error("Error fetching pitches:", error);
    }
  };

  const fetchFacilities = async (groundId) => {
    try {
      const response = await axios.get(

        `${apiConfig.GROUND_OWNER_URL}/ground_detail/facilities/${groundId}`

      );
      setFacilities(response.data || []);
    } catch (error) {
      console.error("Error fetching stadium facilities:", error);
    }
  };

  const fetchEquipment = async (groundId) => {
    try {
      const response = await axios.get(

        `${apiConfig.GROUND_OWNER_URL}/ground_detail/equipments/${groundId}`

      );
      setEquipment(response.data || []);
    } catch (error) {
      console.error("Error fetching equipment:", error);
    }
  };

  const handleDurationChange = (duration) => {
    if (duration === "60 Mins") {
      setSelectedPrice(pricePer60Mins);
    } else if (duration === "90 Mins") {
      setSelectedPrice(pricePer90Mins);
    }
  };

  const fetchReviews = async (groundId) => {
    try {
      const response = await axios.get(

        `${apiConfig.SPORTSPOT_MAIN_URL}/review/get_reviews/${groundId}` // Fetch reviews for the specific ground

      );
      setReviews(response.data);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  const addReview = async (review) => {
    const email = sessionStorage.getItem("email");
    if (null == email) {
      setLogError(
        "Your review will not be submitted because you are not logged in!"
      );
      return;
    }
    review.user_id = email;
    
    try {
      const response = await axios.post(
        `${apiConfig.SPORTSPOT_MAIN_URL}/review/post_review`,
        review
      );
      if (response.status === 201) {
        fetchReviews(groundData.id);
      }
    } catch (error) {
      console.error("Error posting review:", error);
    }
  };

  const handleLocationSelect = (lat, lng) => {};

  if (!groundData || loading) {
    return (
      <div className="container text-center py-5">
        <div className="spinner-border" role="status" style={{color: "#ff3030"}}>
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="ground-details-page py-4">
      {/* Error Messages */}
      {(errors || logErrors) && (
        <div className="container mb-4">
          <div className="alert" style={{backgroundColor: "#242424", color: "#ff3030", border: "1px solid #ff3030"}}>
            {errors || logErrors}
          </div>
        </div>
      )}

      {/* Main Ground Details Section */}
      <div className="container">
        <div className="row g-4">
          {/* Left Column - Ground Info & Images */}
          <div className="col-lg-8">
            {/* Ground Header */}
            <div className="ground-header card mb-4" style={{backgroundColor: "#242424", border: "1px solid #333"}}>
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-start">
                  <div>
                    <h1 className="mb-2" style={{color: "#ff3030"}}>{groundData.name}</h1>
                    <div className="d-flex align-items-center mb-2">
                      <FaMapMarkerAlt className="me-2" style={{color: "#ff3030"}} />
                      <p className="m-0" style={{color: "#ffffff"}}>{groundData.address || "No location available"}</p>
                    </div>
                    <div className="d-flex flex-wrap gap-3">
                      <div className="venue-detail d-flex align-items-center">
                        <FaClock className="me-1" style={{color: "#ff3030"}} />
                        <span style={{color: "#ffffff"}}>{groundData.sportshours || "01:00PM - 01:00AM"}</span>
                      </div>
                      <div className="venue-detail d-flex align-items-center">
                        <FaInfoCircle className="me-1" style={{color: "#ff3030"}} />
                        <span style={{color: "#ffffff"}}>{groundData.stadiumtype || "OutDoor"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="text-end">
                    <div className="d-flex align-items-center mb-2">
                      <div className="rating me-2">
                        {[...Array(5)].map((_, index) => (
                          <FaStar
                            key={index}
                            className={index < Math.floor(groundData.rating) ? "me-1" : "me-1 text-muted"}
                            style={{color: index < Math.floor(groundData.rating) ? "#ff3030" : "#666"}}
                          />
                        ))}
                      </div>
                      <span className="fw-bold" style={{color: "#ffffff"}}>{groundData.rating}/5</span>
                    </div>
                    <button
                      onClick={toggleFavorite}
                      className="btn d-flex align-items-center"
                      style={{backgroundColor: "transparent", color: "#ff3030", border: "none"}}
                    >
                      {isFavorite ? (
                        <>
                          <FaHeart className="me-1" style={{color: "#ff3030"}} />
                          <span>Remove from Favorites</span>
                        </>
                      ) : (
                        <>
                          <FaRegHeart className="me-1" style={{color: "#ff3030"}} />
                          <span>Add to Favorites</span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Ground Image Carousel */}
            <div className="ground-images card mb-4" style={{backgroundColor: "#242424", border: "1px solid #333"}}>
              <div className="card-body p-0">
                <div id="groundCarousel" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-indicators">
                    {images.map((_, index) => (
                      <button
                        key={index}
                        type="button"
                        data-bs-target="#groundCarousel"
                        data-bs-slide-to={index}
                        className={activeImageIndex === index ? "active" : ""}
                        onClick={() => setActiveImageIndex(index)}
                        aria-current={activeImageIndex === index}
                        aria-label={`Slide ${index + 1}`}
                      ></button>
                    ))}
                  </div>
                  <div className="carousel-inner">
                    {images.length > 0 ? (
                      images.map((img, index) => (
                        <div
                          key={index}
                          className={`carousel-item ${activeImageIndex === index ? "active" : ""}`}
                        >
                          <img
                            src={img}
                            className="d-block w-100"
                            alt={`${groundData.name} view ${index + 1}`}
                            style={{ height: "400px", objectFit: "cover" }}
                          />
                        </div>
                      ))
                    ) : (
                      <div className="carousel-item active">
                        <div className="no-image-placeholder d-flex justify-content-center align-items-center" style={{ height: "400px", backgroundColor: "#1e1e1e" }}>
                          <p className="mb-0" style={{color: "#ffffff"}}>No images available</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#groundCarousel"
                    data-bs-slide="prev"
                  >
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#groundCarousel"
                    data-bs-slide="next"
                  >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>

            {/* Ground Description */}
            <div className="ground-description card mb-4" style={{backgroundColor: "#242424", border: "1px solid #333"}}>
              <div className="card-body">
                <h2 className="mb-3" style={{color: "#ff3030"}}>About This Ground</h2>
                <p style={{color: "#ffffff"}}>{groundData.description || "No description available"}</p>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <h3 className="mb-0" style={{color: "#ff3030"}}>Price</h3>
                  <p className="price-display mb-0 fs-4 fw-bold" style={{color: "#ffffff"}}>Rs.{selectedPrice || "N/A"}/Hour</p>
                </div>
              </div>
            </div>

            {/* Pitch Types */}
            <div className="pitch-types card mb-4" style={{backgroundColor: "#242424", border: "1px solid #333"}}>
              <div className="card-header" style={{backgroundColor: "#1e1e1e", borderBottom: "1px solid #333"}}>
                <h2 className="mb-0" style={{color: "#ff3030"}}>Available Pitch Types (Choose one)</h2>
              </div>
              <div className="card-body">
                <div className="row g-3">
                  {pitches.length > 0 ? (
                    pitches.map((pitch, index) => (
                      <div className="col-md-6 col-lg-4" key={index}>
                        <div 
                          className={`pitch-item p-3 text-center ${selectedPitch === pitch ? "selected" : ""}`}
                          onClick={() => handlePitchClick(pitch)}
                          style={{
                            backgroundColor: selectedPitch === pitch ? "#ff3030" : "#1e1e1e",
                            color: "#ffffff",
                            borderRadius: "8px",
                            cursor: "pointer",
                            border: `1px solid ${selectedPitch === pitch ? "#ff3030" : "#333"}`,
                            transition: "all 0.3s ease"
                          }}
                        >
                          <h5 
                            className={`mb-2 ${selectedPitch === pitch ? "pitch-name-selected" : "pitch-name"}`}
                          >
                            {pitch.name}
                          </h5>
                          <p className="mb-1" style={{color: "#ffffff"}}>Type: {pitch.game_type}</p>
                          <p className="mb-1">Size: {pitch.length}m × {pitch.width}m</p>
                          <p className="mb-0">60 min: Rs.{pitch.price_per_60mins}</p>
                          <p className="mb-0">90 min: Rs.{pitch.price_per_90mins}</p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-12">
                      <p className="text-center mb-0" style={{color: "#ffffff"}}>No pitch types available</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Facilities & Equipment */}
            <div className="row g-4 mb-4">
              <div className="col-md-6">
                <div className="facilities card h-100" style={{backgroundColor: "#242424", border: "1px solid #333"}}>
                  <div className="card-header" style={{backgroundColor: "#1e1e1e", borderBottom: "1px solid #333"}}>
                    <h2 className="mb-0" style={{color: "#ff3030"}}>Stadium Facilities</h2>
                  </div>
                  <div className="card-body">
                    <div className="d-flex flex-wrap gap-2">
                      {facilities.length > 0 ? (
                        facilities.map((item, index) => (
                          <span
                            key={index}
                            className="facility-badge"
                            style={{
                              backgroundColor: "#1e1e1e",
                              color: "#ffffff",
                              padding: "6px 12px",
                              borderRadius: "30px",
                              border: "1px solid #333",
                              display: "inline-block",
                              margin: "3px"
                            }}
                          >
                            {item}
                          </span>
                        ))
                      ) : (
                        <p className="mb-0" style={{color: "#ffffff"}}>No facilities available</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="equipment card h-100" style={{backgroundColor: "#242424", border: "1px solid #333"}}>
                  <div className="card-header" style={{backgroundColor: "#1e1e1e", borderBottom: "1px solid #333"}}>
                    <h2 className="mb-0" style={{color: "#ff3030"}}>Equipment Provided</h2>
                  </div>
                  <div className="card-body">
                    <div className="d-flex flex-wrap gap-2">
                      {equipment.length > 0 ? (
                        equipment.map((item, index) => (
                          <span
                            key={index}
                            className="equipment-badge"
                            style={{
                              backgroundColor: "#1e1e1e",
                              color: "#ffffff",
                              padding: "6px 12px",
                              borderRadius: "30px",
                              border: "1px solid #333",
                              display: "inline-block",
                              margin: "3px"
                            }}
                          >
                            {item}
                          </span>
                        ))
                      ) : (
                        <p className="mb-0" style={{color: "#ffffff"}}>No equipment provided</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Map Section */}
            <div className="location-map card mb-4" style={{backgroundColor: "#242424", border: "1px solid #333"}}>
              <div className="card-header" style={{backgroundColor: "#1e1e1e", borderBottom: "1px solid #333"}}>
                <h2 className="mb-0" style={{color: "#ff3030"}}>Location</h2>
              </div>
              <div className="card-body p-0">
                <Map1
                  onLocationSelect={handleLocationSelect}
                  initialLat={groundData.latitude}
                  initialLng={groundData.longitude}
                />
              </div>
            </div>

            {/* Reviews Section */}
            <div className="reviews card" style={{backgroundColor: "#242424", border: "1px solid #333"}}>
              <div className="card-header d-flex justify-content-between align-items-center" style={{backgroundColor: "#1e1e1e", borderBottom: "1px solid #333"}}>
                <h2 className="mb-0" style={{color: "#ff3030"}}>Customer Reviews</h2>
                <span className="badge rounded-pill" style={{backgroundColor: "#ff3030"}}>
                  {reviews.length} reviews
                </span>
              </div>
              <div className="card-body">
                <Review groundId={groundData.id} addReview={addReview} />
                
                {reviews.length > 0 ? (
                  <div className="review-list">
                    {reviews.map((review, index) => (
                      <div key={index} className="review-item mb-4">
                        <div className="d-flex justify-content-between mb-2">
                          <h4 className="mb-0 fs-5" style={{color: "#ff3030"}}>{review.user_id}</h4>
                          <div className="d-flex">
                            {[...Array(5)].map((_, i) => (
                              <FaStar
                                key={i}
                                style={{color: i < review.rating ? "#ff3030" : "#666"}}
                              />
                            ))}
                          </div>
                        </div>
                        <p className="mb-0" style={{color: "#ffffff"}}>{review.comment}</p>
                        {index < reviews.length - 1 && <hr className="my-3" style={{backgroundColor: "#333", borderColor: "#333"}} />}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-center mt-3" style={{color: "#ffffff"}}>No reviews yet. Be the first to review!</p>
                )}
              </div>
            </div>
          </div>
          
          {/* Right Column - Booking Section */}
          <div className="col-lg-4">
            <div className="booking-section card sticky-top" style={{backgroundColor: "#242424", border: "1px solid #333", top: "20px"}}>
              <div className="card-header" style={{backgroundColor: "#1e1e1e", borderBottom: "1px solid #333"}}>
                <h2 className="mb-0" style={{color: "#ff3030"}}>Book This Venue</h2>
              </div>
              <div className="card-body">
                <Booking
                  selectedPitch={selectedPitch}
                  onDurationChange={handleDurationChange}
                  selectedGroundID={groundData.id}
                  selectedGroundName={groundData.name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}