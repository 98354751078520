import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo_icon from "../Assets/logo.png";
import prof_icon from "../Assets/profile.png";
import { FaHome, FaHeart, FaCalendarAlt, FaQuestion, FaUser, FaSignOutAlt, FaSignInAlt, FaUserPlus } from "react-icons/fa";

export default function Navbar(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    
    if (token) {
      try {
        // More robust token validation
        const base64Url = token.split(".")[1];
        const base64 = decodeURIComponent(
          atob(base64Url)
            .split("")
            .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
            .join("")
        );
        const decodedToken = JSON.parse(base64);
        
        // Check if token is still valid (not expired)
        if (decodedToken && (!decodedToken.exp || decodedToken.exp * 1000 > Date.now())) {
          setIsLoggedIn(true);
        } else {
          // Token expired, clear session
          sessionStorage.clear();
          setIsLoggedIn(false);
        }
      } catch (error) {
        // Invalid token format
        console.error("Token validation error:", error);
        sessionStorage.clear();
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [location.pathname]);

  const ChangeAuthSign = () => {
    props.toggleAuthMode("SignUp");
  };

  const ChangeAuthLog = () => {
    props.toggleAuthMode("LogIn");
  };
  
  const signOut = () => {
    sessionStorage.clear();
    setIsLoggedIn(false);
    window.location.href = "/";
  };

  const parseJwt = (token) => {
    if (!token) return null;
    try {
      const base64Url = token.split(".")[1];
      const base64 = decodeURIComponent(
        atob(base64Url)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      return JSON.parse(base64);
    } catch (error) {
      console.error("Error parsing JWT", error);
      return null;
    }
  };

  const allowedRoutesLoc = ["/", "/ground", "/help", "/authpage", "/profile"];
  const allowedRoutes = ["/upcoming-bookings", "/favorites", "/profile"];

  return (
    <nav className="navbar px-3 py-3 d-flex flex-row flex-wrap justify-content-between align-items-center">
      <div className="nav-com d-flex align-items-center">
        <Link to="/" className="text-decoration-none d-flex align-items-center">
          <img
            src={logo_icon}
            alt="Logo"
            className="img-fluid me-3 mb-0"
            style={{ height: "40px", width: "40px" }}
          />
          <h1 className="fs-4 m-0 fw-bold">{props.title}</h1>
        </Link>
      </div>

      {(allowedRoutesLoc.includes(location.pathname) || allowedRoutes.includes(location.pathname)) && (
        <div className="d-flex gap-4 align-items-center">
          <Link to="/" className="nav-link d-flex align-items-center">
            <FaHome className="me-1" />
            <span>Home</span>
          </Link>
          <Link to="/help" className="nav-link d-flex align-items-center">
            <FaQuestion className="me-1" />
            <span>Help</span>
          </Link>
        </div>
      )}
      
      <div className="dropdown">
        <button
          className="btn btn-dark border-0 rounded-circle shadow-sm d-flex align-items-center justify-content-center p-0"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{ width: "45px", height: "45px", background: "#1e1e1e" }}
        >
          <img
            src={prof_icon}
            alt="Profile Icon"
            className="rounded-circle"
            style={{ height: "40px", width: "40px" }}
          />
        </button>
        <ul className="dropdown-menu dropdown-menu-end mt-2">
          {isLoggedIn ? (
            <>
              <li>
                <div className="px-4 pt-3 pb-3 text-center">
                  <img
                    src={prof_icon}
                    alt="User"
                    className="rounded-circle mb-3"
                    style={{ width: "80px", height: "80px" }}
                  />
                  <h6 className="dropdown-header">My Account</h6>
                </div>
              </li>
              <li><hr className="dropdown-divider" /></li>
              <li>
                <Link
                  to="/profile"
                  className="dropdown-item d-flex align-items-center"
                >
                  <FaUser className="me-2" />
                  <span>View Profile</span>
                </Link>
              </li>
              <li><hr className="dropdown-divider" /></li>
              <li>
                <button
                  onClick={signOut}
                  className="dropdown-item d-flex align-items-center"
                >
                  <FaSignOutAlt className="me-2" />
                  <span>Sign Out</span>
                </button>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link
                  to="./authpage"
                  className="dropdown-item d-flex align-items-center"
                  onClick={ChangeAuthSign}
                >
                  <FaUserPlus className="me-2" />
                  <span>Sign Up</span>
                </Link>
              </li>
              <li><hr className="dropdown-divider" /></li>
              <li>
                <Link
                  to="./authpage"
                  className="dropdown-item d-flex align-items-center"
                  onClick={ChangeAuthLog}
                >
                  <FaSignInAlt className="me-2" />
                  <span>Login</span>
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
}