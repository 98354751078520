import apiConfig from './apiConfig';
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaMapMarkerAlt, FaSearch, FaStar } from "react-icons/fa";
import stadium_icon from "../Assets/sign_Bg.jpeg";
import cricket_icon from "../Assets/cricket.jpg";
import badminton_icon from "../Assets/Shuttle.jpg";
import football_icon from "../Assets/football.jpg";
import volleyball_icon from "../Assets/VolleyBall.jpg";
import auth_bg from "../Assets/Owner_Auth_Bg.png";
import Map from "./Map";
import SearchBar from "./Searchbar";

export default function Home() {
  const Sports_icon = "/sports_icon.png"; // You may need to add this image to your assets
  const location = useLocation();
  const navigate = useNavigate();
  const [grounds, setGrounds] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredGrounds, setFilteredGrounds] = useState([]);
  const [groundImages, setGroundImages] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGrounds = async () => {
      try {

        setLoading(true);
        const response = await fetch(`${apiConfig.SPORTSPOT_MAIN_URL}/ground_list`);

        const data = await response.json();
        setGrounds(data);
        setFilteredGrounds(data);

        // Fetch images for each ground
        const imagePromises = data.map((ground) =>
          fetch(
            `${apiConfig.SPORTSPOT_MAIN_URL}/ground_list/ground_img_by_id/${ground.id}`
          )
            .then((res) => res.json())
            .then((imgData) => ({ groundId: ground.id, image: imgData.image }))
            .catch(() => ({ groundId: ground.id, image: null }))
        );

        const images = await Promise.all(imagePromises);
        const imageMap = {};
        images.forEach((img) => {
          imageMap[img.groundId] = img.image;
        });
        setGroundImages(imageMap);
      } catch (error) {
        console.error("Error fetching grounds or images:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGrounds();
  }, []);

  const handleClick = (ground) => {
    sessionStorage.setItem("ground_data", ground.id);
    navigate("/ground", { state: { ground } });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Filter grounds based on the address and name
    const filtered = grounds.filter((ground) =>
      ground.address.toLowerCase().includes(value.toLowerCase()) ||
      ground.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredGrounds(filtered);
  };

  const handleGameClick = async (gameType) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${apiConfig.SPORTSPOT_MAIN_URL}/ground_list/search_grounds_by_game/${gameType}`
      );
      const data = await response.json();
      setFilteredGrounds(data);
    } catch (error) {
      console.error("Error fetching grounds for game type:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to truncate ground name if it's longer than 15 characters
  const truncateName = (name) => {
    return name.length > 15 ? name.slice(0, 13) + "..." : name;
  };

  const handleLocationSelect = (lat, lng) => {
    console.log("Selected Location:", lat, lng);
    setSelectedLocation({ lat, lng });
  };

  const RefreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    const results = grounds.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredGrounds(results);
  }, [searchQuery, grounds]);

  const renderHeroSection = () => {
    return (
      <div
        style={{
          backgroundImage: `url(${auth_bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "50vh",
          position: "relative",
        }}
      >
        <div 
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
        ></div>
        <div className="container position-relative py-5 d-flex flex-column align-items-center justify-content-center">
          <h1 className="display-4 text-center fw-bold mb-5 mt-4">
            Find Your Perfect <span style={{color: "#ff3030"}}>Sports Venue</span>
          </h1>
          <p className="lead text-center mb-4">
            Book stadiums, pitches, and courts for your favorite sports
          </p>
          
          <div className="search-container w-100 d-flex flex-wrap justify-content-center gap-3 py-3">

            {/* Search by name */}
            <div className="search-box p-0">
              <div className="input-group" style={{
                backgroundColor: "#242424",
                borderRadius: "30px",
                border: "1px solid #ff3030",
                overflow: "hidden",
                height: "45px",
                minWidth: "250px"
              }}>
                <input
                  type="text"
                  className="form-control border-0"
                  placeholder="Search grounds by name..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{
                    color: "white",
                    backgroundColor: "#242424",
                    borderColor: "transparent",
                    paddingLeft: "15px",
                    height: "100%"
                  }}
                />
                <button className="btn" style={{
                  backgroundColor: "#ff3030", 
                  borderColor: "#ff3030",
                  width: "45px",
                  borderRadius: "0 30px 30px 0"
                }}>
                  <FaSearch style={{color: "white"}} />
                </button>
              </div>
            </div>
            
            {/* Sport selection dropdown */}
            <div className="dropdown">
              <button
                className="btn dropdown-toggle px-4 py-2"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ 
                  color: "white",
                  backgroundColor: "#242424",
                  borderColor: "#ff3030",
                  borderRadius: "30px",
                  minWidth: "250px",
                  height: "45px",
                  textAlign: "left",
                  border: "1px solid #ff3030"
                }}
              >
                Select Sport
              </button>
              <ul className="dropdown-menu">
                <li className="dropdown-item" onClick={RefreshPage}>
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={Sports_icon}
                      alt="All Sports"
                      className="rounded-circle"
                      width="40"
                      height="40"
                    />
                    <span>All Sports</span>
                  </div>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li className="dropdown-item" onClick={() => handleGameClick("Cricket")}>
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={cricket_icon}
                      alt="Cricket"
                      className="rounded-circle"
                      width="40"
                      height="40"
                    />
                    <span>Cricket</span>
                  </div>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li className="dropdown-item" onClick={() => handleGameClick("Football")}>
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={football_icon}
                      alt="Football"
                      className="rounded-circle"
                      width="40"
                      height="40"
                    />
                    <span>Football</span>
                  </div>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li className="dropdown-item" onClick={() => handleGameClick("Badminton")}>
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={badminton_icon}
                      alt="Badminton"
                      className="rounded-circle"
                      width="40"
                      height="40"
                    />
                    <span>Badminton</span>
                  </div>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li className="dropdown-item" onClick={() => handleGameClick("Volleyball")}>
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={volleyball_icon}
                      alt="Volleyball"
                      className="rounded-circle"
                      width="40"
                      height="40"
                    />
                    <span>Volleyball</span>
                  </div>
                </li>
              </ul>
            </div>
            
            {/* Location search */}
            <div className="location-search">
              <SearchBar value={searchTerm} onChange={handleSearchChange} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="nav-style">
        <div className="nav-underline"></div>
      </div>

      {location.pathname !== "/authpage" &&
      location.pathname !== "/ground" &&
      location.pathname !== "/upcoming-bookings" &&
      location.pathname !== "/help" &&
      location.pathname !== "/favorites" && renderHeroSection()}

      {location.pathname !== "/authpage" &&
        location.pathname !== "/ground" &&
        location.pathname !== "/upcoming-bookings" &&
        location.pathname !== "/help" &&
        location.pathname !== "/favorites" && (
          <>
            <div
              className="container py-5"
              id="home"
            >
              <h2 className="text-center mb-5 fw-bold">
                Suggested Grounds
              </h2>
              
              {loading ? (
                <div className="text-center py-5">
                  <div className="spinner-border" role="status" style={{color: "#ff3030"}}>
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="row g-4">
                  {filteredGrounds.length > 0 ? (
                    filteredGrounds.map((ground, index) => (
                      <div className="col-md-6 col-lg-4" key={index}>
                        <div className="pitches h-100 d-flex flex-column">
                          <img
                            className="pitches-img"
                            src={
                              groundImages[ground.id]
                                ? `data:image/jpeg;base64,${
                                    groundImages[ground.id]
                                  }`
                                : stadium_icon
                            }
                            alt={`${ground.name}-Picture`}
                          />
                          <div className="info flex-grow-1 d-flex flex-column">
                            <div className="info-text w-100">
                              <div className="d-flex justify-content-between align-items-center mb-3">
                                <h3 className="mb-0 fs-5 fw-bold">{truncateName(ground.name)}</h3>
                                <div className="d-flex align-items-center">
                                  <FaStar className="me-1" style={{color: "#ff3030"}} />
                                  <span className="fw-bold">{ground.rating}/5</span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mb-3">
                                <FaMapMarkerAlt className="me-2" style={{color: "#ff3030"}} />
                                <p className="m-0">{ground.address || "No location available"}</p>
                              </div>
                            </div>
                            <button 
                              onClick={() => handleClick(ground)} 
                              className="mt-auto py-2 px-4 fw-bold"
                            >
                              Book Now
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-12 text-center py-5">
                      <h5>No grounds available matching your search!</h5>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Map section - Centered */}
            <div className="container-fluid py-5 bg-dark">
              <div className="container d-flex flex-column align-items-center justify-content-center">
                <h2 className="text-center mb-5">Find Grounds Near You</h2>
                <div className="map-container w-100">
                  <Map
                    onLocationSelect={handleLocationSelect}
                    initialGrounds={filteredGrounds}
                  />
                </div>
                <div className="text-center mt-4">
                  <p>Click on markers to see ground details</p>
                </div>
              </div>
            </div>
            
            {/* Why Choose SportSpot - Centered */}
            <div className="container py-5 d-flex justify-content-center">
              <div className="why-choose-us p-4" style={{ maxWidth: "600px" }}>
                <h3 className="text-center mb-4">Why Choose SportSpot?</h3>
                <ul className="mt-3">
                  <li className="mb-2">Easy booking process</li>
                  <li className="mb-2">Wide variety of sports venues</li>
                  <li className="mb-2">Real user reviews and ratings</li>
                  <li className="mb-2">Secure payment options</li>
                  <li className="mb-2">Manage your bookings easily</li>
                </ul>
              </div>
            </div>
          </>
        )}
    </>
  );
}