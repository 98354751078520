// API Configuration for SportSpot Applications
// This is the central configuration file for all API endpoints
// All frontend applications should import and use these variables instead of hardcoded URLs

// Railway deployed backend URLs
const API_URLS = {
  // User authentication and main app backend
  SPORTSPOT_AUTH: "https://sportspot-auth.up.railway.app",
  SPORTSPOT_MAIN: "https://sportspot-users.up.railway.app",
  
  // Ground owner backend
  GROUND_OWNER: "https://sportspot-production.up.railway.app",
  
  // Admin backend
  ADMIN: "https://admin-production-629c.up.railway.app",
  
  // Database connection (if needed directly from frontend)
  DATABASE: "tramway.proxy.rlwy.net:48924"
};

// Port configurations
const API_PORTS = {
  SPORTSPOT_AUTH: 8080,
  SPORTSPOT_MAIN: 8000,
  GROUND_OWNER: 8090,
  ADMIN: 8070,
  DATABASE: 5432
};

// Generate full URLs with ports
const getFullUrl = (service) => {
  return `${API_URLS[service]}`;
};

// Export configuration
module.exports = {
  // Base URLs without ports
  API_URLS,
  
  // Full URLs for each service
  SPORTSPOT_AUTH_URL: getFullUrl('SPORTSPOT_AUTH'),
  SPORTSPOT_MAIN_URL: getFullUrl('SPORTSPOT_MAIN'),
  GROUND_OWNER_URL: getFullUrl('GROUND_OWNER'),
  ADMIN_URL: getFullUrl('ADMIN'),
  
  // Port numbers
  API_PORTS
};