import React, { useState, useEffect } from "react";

function Review({ addReview }) {
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");
  const [rating, setRating] = useState(0);
  const [groundData, setGroundData] = useState(null);

  useEffect(() => {
    try {
      const storedGroundData = sessionStorage.getItem("ground_data");
      setGroundData(storedGroundData);
    } catch (error) {
      console.error("Failed to parse ground_data from sessionStorage:", error);
    }
  }, []); // Runs only once when the component mounts

  const email = sessionStorage.getItem("email"); // No need to store in state

  const handleStarClick = (index) => {
    setRating(index + 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!groundData) {
      setError("Ground data is missing or invalid. Please refresh the page.");
      return;
    }

    const newReview = {
      user_id: email,
      comment,
      rating,
      ground: groundData,
    };
    addReview(newReview);
    setComment("");
    setRating(0);
  };

  if (!groundData) {
    return (
      <div className="alert alert-danger" role="alert">
        Ground information is not available. Please try again later.
      </div>
    );
  }

  return (
    <>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <form
        className="review-form p-4 mb-4"
        onSubmit={handleSubmit}
        style={{ 
          backgroundColor: "var(--card-bg)", 
          borderRadius: "8px",
          border: "1px solid var(--border-color)"
        }}
      >
        <div className="star-rating mb-3">
          <div className="d-flex">
            <p className="me-3 mb-0">Your Rating:</p>
            {[...Array(5)].map((_, index) => (
              <span
                key={index}
                className={`star ${index < rating ? "selected" : ""}`}
                onClick={() => handleStarClick(index)}
                style={{ 
                  fontSize: "2rem", 
                  cursor: "pointer",
                  color: index < rating ? "var(--primary-color)" : "#666"
                }}
              >
                ★
              </span>
            ))}
          </div>
        </div>
        <div className="mb-3">
          <textarea
            className="form-control"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Write your review here"
            rows="4"
            required
            style={{ 
              backgroundColor: "var(--input-bg)",
              color: "var(--text-primary)",
              border: "1px solid var(--border-color)"
            }}
          ></textarea>
        </div>
        <button 
          className="review-btn px-4 py-2" 
          type="submit"
          style={{
            backgroundColor: "var(--primary-color)",
            color: "var(--text-primary)",
            border: "none",
            borderRadius: "5px"
          }}
        >
          Submit Review
        </button>
      </form>
      <div className="nav-style mb-3">
        <div className="nav-underline"></div>
      </div>
    </>
  );
}

export default Review;